.row_base {
  background-color: #fff;
  height: 30px;
}

.row_base:hover {
  background-color: #f9f9f9;
  .sticky {
    background-color: #f9f9f9 !important;
  }
}

.table_cell {
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  text-align: left;
  font-size: 12px;
  padding: 2px 5px 2px 5px;
  cursor: pointer;
  position: relative;
}

.table_cell.sticky {
  position: sticky;
  left: 32px;
  z-index: 201;
  background-color: #ffffff;
}

.table_cell::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  border-right: 1.5px solid #f2f2f2;
}

.table_cell:focus-within:not(:first-child) {
  outline: 1px solid rgba(0, 122, 240, 1);
  border-radius: 2px;
  z-index: 200;
  &:after {
    border-color: transparent;
  }
}

.text_cell_input {
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
}

.number_cell_input {
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
  text-align: end;
}

.number_cell_input:focus {
  outline: none;
  border: none;
}

.number_cell_input::-webkit-inner-spin-button,
.number_cell_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text_cell_input:focus {
  outline: none;
  border: none;
}

.textarea_cell {
  display: flex;
  width: 100%;
}

.expandable_cell--focused {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  max-height: 200px;
  outline: 1px solid #0066ff;
  z-index: 5;
  background: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  width: calc(100% + 30px);
  white-space: initial;
}

.resize_handle {
  outline: none;
  border: none;
  width: 16px;
  height: 16px;
}

.resize_handle:hover {
  cursor: pointer;
  background-color: rgba(0, 122, 240, 0.17);
  border-radius: 1px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.clear_filters_btn {
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: #8997ba;
  font-size: 12px;
}

.empty_state {
  display: flex;
  height: 100vh;
  position: absolute;
  flex-direction: column;
  left: 50%;
}

.group-picker-item:hover {
  background-color: #e4f0f6;
}
