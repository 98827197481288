.image_drop_zone {
  display: grid;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  max-height: 400px;
  overflow: auto;
}

.img_card {
  height: 215px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.img_card_file {
  cursor: move;
  height: 170px;
  width: 170px;
  object-fit: cover;
  background-color: #f9f9f9;
}

.img_card_nav {
  display: flex;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  justify-content: space-between;
}

.img_card_actions {
  display: flex;
  gap: 2px;
}

.img_card_action_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 3px;
  background: #ffffff;
  outline: none;
  border: none;
}

.img_card_action_button:hover {
  background-color: #f2f2f2;
}

.upload_more {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 2px;
  cursor: pointer;
}

.upload_more:hover {
  background-color: #f2f2f2;
}

.upload_file_title {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}
