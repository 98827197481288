.base_select__control {
  min-height: 30px !important;
  box-sizing: border-box;
  border: 1px solid #f9f9f9;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 12px;
}

.base_select__value-container {
  padding: 0;
  font-size: 12px;
  align-items: baseline;
  min-height: 18px;
}

.base_select__value-container--is-multi {
  overflow: scroll !important;
  flex-wrap: nowrap !important;
}

.base_select__single-value {
  height: 100%;
  display: flex;
  align-items: center;
}

.base_select__multi-value {
  display: flex;
  align-items: center;
  margin: 2px;
  background-color: #ddc7f9;
  border-radius: 2px;
  font-size: 12px;
  height: 18px;
  padding: 2px;
  min-width: min-content !important;
}

.base_select__multi-value__label {
  display: flex;
  align-items: center;
  height: 100%;
}

.base_select__multi-value__remove {
  color: #525252;
  height: 10px;
  margin-left: 2px;
  width: 10px;
}

.base_select__control--menu-is-open {
  border-top: 1px solid #0066ff;
  border-left: 1px solid #0066ff;
  border-right: 1px solid #0066ff;
  border-radius: 3px 3px 0 0;
}

.base_select__control.base_select__control--is-focused:not(.base_select__control--menu-is-open) {
  border-bottom: 1px solid #0066ff;
  border-top: 1px solid #0066ff;
  border-left: 1px solid #0066ff;
  border-right: 1px solid #0066ff;
  border-radius: 3px;
}

.base_select__placeholder {
  color: #d4d4d4;
  font-size: 12px;
}

.base_select__menu {
  border-top: none;
  border-bottom: 1px solid #0066ff;
  border-left: 1px solid #0066ff;
  border-right: 1px solid #0066ff;
  border-radius: 0 0 3px 3px;
  width: 100%;
  overflow-y: scroll !important;
  background-color: #ffff;
  font-size: 12px;
}

.base_select__option {
  height: 24px;
  background-color: #ffff;
  padding: 5px;
  display: flex !important;
  align-items: center;
}

.base_select__option.base_select__option--is-focused {
  background-color: #e4f0f6;
  cursor: pointer;
}
