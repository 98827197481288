.tag_wrapper {
  display: inline-flex;
  padding: 3px 5px 3px 5px;
  border-radius: 3px;
  gap: 5px;
  font-size: 12px;
  text-align: center;
  min-width: fit-content;
}

.tag_wrapper--primary {
  background-color: #ddc7f9;
}

.tag_wrapper--secondary {
  background-color: #4d68ad;
  color: white;
}

.tag_close_handle {
  cursor: pointer;
  color: #8997ba;
  font-size: 8px;
  display: flex;
  align-items: center;
}

.tag_row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
}
