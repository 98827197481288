.arrow-carrousel__wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.arrow-carrousel {
  width: 34px;
  height: 34px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.arrow-left {
    margin-left: 14px;
  }

  &.arrow-right {
    margin-right: 14px;
  }
}