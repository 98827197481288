.rf-form {
}

.rf-input__wrapper {
  display: flex;
  position: relative;
}

.rf-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  span {
    font-size: 14px;
    font-family: 'Object Sans Medium';
    text-transform: capitalize;
    margin-left: 5px;
  }
  > p {
    margin: 3px 0px 6px 0px;
    color: #7d8693;
    font-size: 14px;
  }
}

.rf-input--error {
  border-color: #da7b7b !important;
}

.rf-password-toggle {
  position: absolute;
  z-index: 4;
  right: 15px;
  top: 50%;
  width: 24px;
  cursor: pointer;
  transform: translateY(-50%);
}

.rf-input {
  background: #ffffff;
  border: 1px solid #d5d4d4;
  border-radius: 3px;
  padding: 17px 15px;
  outline: none;
  z-index: 2;
  flex-grow: 1;
  position: relative;
  &:disabled {
    background: #f9f9f9;
    cursor: not-allowed;
  }
  &:focus {
    border-color: #000000;
  }
}

.rf-error {
  background-color: #da7b7b;
  position: relative;
  top: -2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  .rf-error--message {
    font-size: 12px;
    color: #ffffff;
    padding: 4px 5px;
    margin: 0;
    // font-family: "Object Sans";
  }
}
