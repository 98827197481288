//////////////////
//// Thread //////
//////////////////

.thread__wrapper {
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.thread__chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  height: calc(100vh - 91px);
  overflow-y: auto;
}

.thread__input-wrapper {
  border-top: 1px solid #f2f2f2;
  margin-top: 20px;
  margin-left: 21px;
  margin-right: 21px;
  padding: 20px 0;
    display: flex;
  input {
    width: 100%;
    border: none;
    outline: none;
  }
}

.thread__input-buttons {
  display: flex;
  img {
    cursor: pointer;
    margin-left: 21px;
  }
}

//////////////////
//// Contacts ////
//////////////////

.contacts__wrapper {
  height: 100vh;
  border-right: 1px solid #f2f2f2;
  padding: 16px 10px 16px 10px;
}

.contacts__search {
  padding-bottom: 10px;
  margin-top: 10px;
  width: 270px;
  display: flex;
  input {
    background-color: #f9f9f9;
    border: none;
    outline: none;
    padding: 7.25px 9px;
    border-radius: 3px;
    width: 100%;
  }
}

.contacts__search-icon {
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
    border-radius: 3px;
  }
}

.contacts__chat {
  position: relative;
  padding-top: 10px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 1px;
    background-color: #f9f9f9;
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.contacts__chat-empty-text {
  font-size: 14px;
  color: #7d8693;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

//////////////////
//// Message /////
//////////////////

.message__wrapper {
  padding: 2.5px 20px;
  font-size: 13px;
  width: 400px;
  &.sender {
    align-self: flex-end;
    .message__bubble {
      background-color: #d1e6d7;
    }
    .message__time {
      text-align: right;
    }
    .message__bubble--downladable {
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    }
    &:hover {
      .message__bubble--downladable {
        border: 1px solid #8f55db;
      }
    }
  }
}

.message__time {
  color: #7d8693;
  font-size: 12px;
  &.narrow {
    margin-top: 34px;
  }
}

.message__bubble {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.message__bubble--downladable {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  margin-top: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s ease all;
  &:hover {
    border: 1px solid #a0a9b7;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }
  .message__bubble-info {
    display: flex;
    img {
      margin-right: 14.76px;
    }
    p {
      font-weight: 700;
      margin: 0 0 4px 0;
    }
  }
}

//////////////////////////
//// Message Details /////
//////////////////////////

.message-details__wrapper {
  width: 345px;
  border-left: 1px solid #f2f2f2;
  padding: 23px 0 23px 20px;
  img {
    margin-right: 10px;
  }
}

.message-details__name {
  font-size: 20px;
  margin-bottom: 15px;
  display: flex;
}

.message-details__active {
  font-size: 12px;
  color: #7d8693;
  margin-bottom: 20px;
}

.message-details__button {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.message-details__info {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  gap: 15px;
  p {
    font-size: 14px;
    color: #111111;
    font-weight: 500;
  }
}

.message-details__price-guide {
  color: #111111;
  &.shadow {
    color: #cccccc;
  }
  &:first-child {
    margin-left: 5px;
  }
}

.message-details__info-products {
  margin-right: 22px;
}

//////////////////////////
//// Message Details /////
//////////////////////////

.message-contact__wrapper {
  display: flex;
  gap: 10px;
  padding: 9px 10px;
  margin-bottom: 5px;
  border-radius: 3px;
  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
  &.active {
    background-color: #f9f9f9;
    .message-contact__activeness {
      color: #000000;
    }
  }
}

.message-contact__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message-contact__name {
  font-size: 14px;
  font-family: 'Object Sans Medium';
}

.message-contact__first-char {
  min-width: 40px;
  height: 40px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-contact__activeness {
  font-size: 13px;
  color: #7d8693;
  font-weight: 400;
}
