.quickview-close-button:hover {
  background-color: #f6f6f6;
  border: 1px solid #f9f9f9;
}

.quick-view__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 12px 16px;
  border-bottom: 1px solid #f2f2f2;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: white;
}

.quick-view__name {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 21px;
}

.quick-view__hero {
  margin-left: 20px;
  padding-right: 20px;
}

.quick-view__container {
  animation: myAnim 0.6s ease 0s 1 normal forwards;
  z-index: 1000000;
  width: 500px;
  height: 100vh;
  overflow: scroll;
  background-color: white;
  position: absolute;
  right: 00px;
  cursor: default;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  top: 0;
}

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.quick-view__variants {
  margin-top: 40px;
  margin-bottom: 10px;
}

.quick-view__variant-name {
  font-size: 12px;
  font-family: 'Object Sans Medium';
  margin-top: 10px;
  margin-bottom: 10px;
}

.quick-view__variant-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.quick-view__variant-option {
  font-size: 14px;
  cursor: pointer;
  padding: 9px 17px;
  border: 1px solid #e5e5e5;
  &.active {
    cursor: auto;
    background-color: #ddc7f9;
    border-color: #000000;
  }
  &.unavailable {
    cursor: not-allowed;
    color: #e5e5e5;
  }
}
