.page-control:hover {
  background-color: #f2f2f2;
}

.pagination_button {
  border-radius: 50%;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: white;
}

.footer-pagination {
  display: flex;
  align-items: center;
  border-left: 1px solid #cccccc;
  padding-left: 10px;
  select {
    width: 50px !important;
    height: auto !important;
    border: none !important;
    margin-right: 10px !important;
    outline: 1px solid rgb(204, 204, 204);
    font-size: 11px !important;
  }
  input {
    outline: none;
  }
}

.pagination_button.disabled {
  opacity: 0.5;
}

.pagination_button--left {
  margin-right: 5px;
}

.pagination_button--right {
  margin-left: 5px;
}

.pagination_button:disabled {
  pointer-events: none;
}

.pagination_button:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
