.text_modal_background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.text_modal {
  display: flex;
  flex-direction: column;
  padding: 6px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 335px;
  max-width: 335px;
  max-height: 248px;
  box-sizing: border-box;
  position: relative;
}

.text_modal_content {
  padding: 10px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  line-height: 1.5;
  height: 248px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.close_handle {
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -10px;
}
