.hero-image {
  border-top: 1px solid #f2f2f2;
  margin-top: 15px;
  p {
    font-size: 14px;
    line-height: 12px;
    margin-bottom: 15px;
  }
}

.hero-image__img-wrapper {
  height: 260px;
  position: relative;
  background-color: #e5e5e5;
  border-radius: 2px;

  .account-profile__avatar {
    &.has-value {
      &:hover {
        .account-profile__avatar--overlay {
          border-radius: 2px;
          opacity: 1;
        }
      }
    }
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 2px;
  }
  &:hover {
    .gallery-image__close {
      // display: flex;
      cursor: pointer;
      width: 20px;
      height: 20px;
      top: -10px;
      right: -10px;
    }
  }
}

.gallery-images {
  border-top: 1px solid #f2f2f2;
  margin-top: 15px;
  p {
    margin: 15px 0 20px;
  }
}

.gallery-images__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.gallery-image--blank {
  background-color: #e5e5e5;
  > img {
    display: none;
  }
  .gallery-image__close {
    display: none !important;
  }
  .gallery-image__add {
    display: flex;
  }
}

.gallery-image {
  width: 70px;
  height: 70px;
  border-radius: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 2px;
  }
  &:hover {
    .gallery-image__close {
      display: flex;
    }
  }
}

.gallery-image__add {
  display: none;
}

.gallery-image__close {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #c4c4c4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  img {
    width: 8px;
  }
}
