.select-wrapper__control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  .select-wrapper__value-container {
    padding-left: 5px;
  }

  .select-wrapper__indicator-separator {
    display: none;
  }

  .select-wrapper__single-value {
    padding: 3px 5px;
    border-radius: 32px;
    width: fit-content;
  }
  .select-wrapper__indicator {
    img {
      height: 12px;
    }
  }
}

.select-wrapper__control {
  // width: 162px!important;
  // border: 1px solid #0066FF!important;
  // box-shadow: none!important;
  // margin-top: 0!important;
  // top: calc(100% + 3px);
  // left: -6.5px!important;
  // border-bottom: 0px!important;
  // border-radius: 0!important
}

.select-wrapper {
  position: absolute !important;
  background-color: white;
  z-index: 202;
  width: 166px !important;
  border: 1px solid #0066ff !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  top: calc(100% - 32px);
  left: -8px !important;
  border-radius: 2px !important;
}

.select-wrapper__menu {
  left: -1px;
  width: 166px !important;
  border: 1px solid #0066ff !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  top: calc(100% + 3px);
  border-top: none !important;
  border-radius: 2px !important;
}

.select-wrapper__menu-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px;
  .select-wrapper__option {
    padding: 3px 5px;
    width: fit-content;
    cursor: pointer;
    border-radius: 32px;
    position: relative;
  }
}

.select-wrapper__option--is-focused {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 149px;
    z-index: -1;
    border-radius: 3px;
    height: 100%;
    background-color: #efefef;
  }
}

.select-value {
  padding: 3px 5px;
  border-radius: 32px;
  margin: 0;
  display: inline-block;
}

.select-value__wrapper {
  position: relative;
}
