@font-face {
  font-family: 'Object Sans';
  src: url('./fonts/PPObjectSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Object Sans Thin';
  src: url('./fonts/PPObjectSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Object Sans Medium';
  src: url('./fonts/PPObjectSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Object Sans Bold';
  src: url('./fonts/PPObjectSans-Bold.woff') format('woff');
}

* {
  font-family: 'Object Sans';
  box-sizing: border-box;
}

body {
  background-color: white;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
  width: 100vw;
  /* border: 1px solid purple; */
}

.button-regular:active {
  box-shadow: 1px 1px 1px #000000;
}

.grey-hover:hover {
  background-color: #f9f9f9;
}

.generic-input::placeholder {
  color: #d5d4d4;
}
