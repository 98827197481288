.dashboard-wrapper {
  padding: 20px;
}

.dropdown__wrapper {
  position: relative;
  &.list-card {
    .dropdown__wrapper-tittle {
      p {
        font-size: 22px;
        margin: 0;
      }
      span {
        margin-left: 10.5px;
        img {
          width: 12px;
        }
      }
    }
  }
}

.dropdown__wrapper-tittle {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 12px;
  span {
    margin-left: 30px;
  }
}

.dropdown__options-wrapper {
  // visibility: hidden;
  // display: none;
  background: #ffffff;
  /* Modal Drop Shadow */

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 145px;
  padding: 10px;
  position: absolute;
  top: 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.dropdown__options-item {
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    background: #d1e6d7;
    border-radius: 3px;
    padding: 5px;
  }
}

.statistic-cards {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  p {
    margin-top: 0;
  }
}

.list-cards__wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.list-card__wrapper {
  flex-grow: 1;
  background-color: #f9f9f9;
  padding: 20px 18px;
  border-radius: 5px;
}

.list-cards__table {
  width: 100%;
  border-spacing: 0;
  tbody {
    background-color: #ffffff;
  }
  thead {
    tr {
      background-color: #f9f9f9;
      th {
        font-size: 12px;
        font-family: 'Object Sans Medium';
        padding-bottom: 6px;
      }
    }
  }
  tr {
    vertical-align: middle;
    td {
      padding: 5px;
      vertical-align: middle;
      border-bottom: 1px solid #f9f9f9;
      p {
        margin: 0;
        margin-left: 5px;
        font-size: 12px;
        display: inline-block;
      }
      &:first-child {
        border-right: 1px solid #f9f9f9;
      }
      &:last-child {
        text-align: center;
      }
    }
  }
}

.statistic-card {
  background: #ffffff;
  padding: 17px;
  flex-grow: 1;
  height: 114px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.statistic-card__values {
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-size: 24px;
    font-family: 'Object Sans Medium';
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    padding: 3px 5px;
    background-color: #d4d4d4;
    border-radius: 32px;
    &.green {
      background-color: #aece8e;
    }
    img {
      margin-right: 10px;
    }
  }
}

.charts-wrapper {
  height: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
