.button-generic__wrapper {
  width: 100%;
  &.button-generic__wrapper--top-separator {
    padding-top: 15px;
    border-top: 1px solid #f2f2f2;
  }
}

.button-generic {
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;
  opacity: 1;
  transition: 0.2s opacity ease;
  font-family: 'Object Sans';
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:active {
    opacity: 0.7;
  }
}

.button--modal {
  text-transform: none;
}

.button-generic:active {
  // box-shadow: 1px 1px 1px #000000;
}
