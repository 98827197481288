.context-menu {
  background: #ffffff;
  /* Cell/Medium Grey */

  border: 1px solid #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  z-index: 1000;
  background-color: 'white';
  position: absolute;
  padding: 9px 0px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  div {
    display: flex;
    align-items: center;
    padding: 5px 13px;
    cursor: pointer;
    &:hover {
      background-color: #f2f2f2;
    }
    img {
      margin-right: 10px;
    }
  }
  p {
    font-size: 13px;
    margin: 0;
  }
}
