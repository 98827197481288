.checkbox_input {
  width: 14px;
  height: 14px;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #000;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.checkbox_input--primary:checked {
  border: 1px solid #aece8e;
  background-color: #aece8e;
}

.checkbox_input--tertiary:checked {
  border: 1px solid #38776b;
  background-color: #d1e6d7;
  color: #38776b;
}

.checkbox_input--secondary:checked {
  border: 1px solid #007af0;
  background-color: #cae1ee;
  color: #007af0;
}

.checkbox_input--checkbox:checked:after {
  content: '';
  transform: rotate(45deg);
  width: 3px;
  height: 7px;
  margin-bottom: 2px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.checkbox_input--checkbox:checked:after {
  content: '';
  transform: rotate(45deg);
  width: 3px;
  height: 7px;
  margin-bottom: 2px;
}

.checkbox_input--primary.checkbox_input--checkbox:checked:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.checkbox_input--tertiary.checkbox_input--checkbox:checked:after {
  border-bottom: 1px solid #38776b;
  border-right: 1px solid #38776b;
}

.checkbox_input--intermediate:checked:after {
  content: '-';
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.checkbox_container {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.checkbox_label {
  margin-left: 5px;
  cursor: pointer;
}
