.left-menu-new {
  /* border: 1px solid red; */
  font-size: 14px;
  overflow: hidden;

  height: 100%;
  padding: 13px;
  background-color: #ffffff;
  border-right: 1px solid #f2f2f2;
  transition: all 0.4s;
  &.collapsed {
    .menu-item-new {
      padding-left: 15px;
      padding-right: 15px;
      // justify-content: center;
    }
    .menu-item-new-left {
      &:before {
        left: -10px !important;
      }
    }
    .menu-item-new-title {
      // width: 0px
    }
    .menu-item-new-left-icon {
      // margin-right: 0;
    }
  }
}

.menu-item-notifications {
  width: 18px;
  height: 18px;
  background-color: #38776b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  span {
    line-height: 13px;
    font-size: 10px;
    color: #ffffff;
  }
}

.menu-item-new-title {
  transition: width 0.4s;
  margin-left: 10px;
  flex-grow: 1;
}

.left-menu-new-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-item-new-left-icon {
  // margin-right: 10px;
  display: flex;
  min-width: 20px;
  justify-content: center;
}

.left-menu-new-top-left {
  display: flex;
  align-items: center;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  &:hover {
    .left-menu-logo-overlay {
      opacity: 1;
    }
  }
}

.left-menu-logo-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: opacity 0.4s;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 40px;
    height: 40px;
    background-color: rgba(17, 17, 17, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// .left-menu-new-top-left img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

.left-menu-new-top-mid {
  margin-left: 11px;
  display: flex;
  align-items: center;
  transition: opacity 0.4s;
}

.left-menu-new-top-right {
  display: flex;
  align-items: center;
}

.left-menu-new-bottom {
  margin-top: 58px;

  color: #7d8693;
}

.menu-item-new {
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 15px;
  position: relative;
  transition: padding 0.4s;
  &.active {
    .menu-item-new-title {
      color: #000000 !important;
    }
    .menu-item-new-left {
      &:before {
        content: '';
        width: 4px;
        height: 100%;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: #000000;
        position: absolute;
        transition: left 0.4s;
        left: -13px;
        top: 0;
      }
    }
  }
}

.menu-item-new:hover {
  background-color: #f9f9f9;
}

.menu-item-new-left {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.left-menu-new-bottom-mid {
  margin-top: 25px;
}
.left-menu-new-bottom-bottom {
  margin-top: 17px;
}

.menu-token {
  background-color: #38776b;
  font-size: 10px;
  color: #ffffff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* transition: all 2s; */
}
