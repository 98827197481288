.account-submenu {
  padding-top: 24px;
  padding-left: 21px;
  padding-right: 21px;
  font-size: 12px;
  overflow: hidden;
  background-color: #ffffff;
  transition: width 0.5s, padding-left 0.5s;
  height: 100%;
  width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  // border-right: 1px solid transparent;
  &.show {
    width: 184px;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid #f2f2f2;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.account-sub__item {
  margin-bottom: 18px;
  font-family: 'Object Sans';
  font-weight: 500;
  color: #000000;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  background-color: transparent;
  padding: 4px 6px;
  transition: 0.3s ease background-color;
  &:hover {
    background: #f2f2f2;
  }
  &:after {
    content: '';
    position: absolute;
    left: -20px;
    height: 25px;
    width: 3px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    background-color: transparent;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  &.active {
    font-family: 'Object Sans Medium';
    &:after {
      background-color: #000000;
    }
  }
}

.account-sub__item--error {
  color: #c02222;
}

.account-sub__section {
  border-top: 1px solid #f2f2f2;
  padding-top: 20px;
}

.account-sub__item:hover {
  font-weight: 700;
}

.account-sub__title {
  font-family: 'Object Sans Bold';
  margin-bottom: 20px;
  font-size: 10px;
  text-transform: uppercase;
}

.account-topbar {
  width: 100%;
  height: 68.5px;
  padding: 16px 16px 16px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  border-bottom: 1px solid #f2f2f2;
}

.account-profile__wrapper {
  // border-top: 1px solid #F2F2F2;
  margin-top: 15px;
}

.account-profile__avatar {
  width: 76px;
  height: 76px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  img {
    object-fit: contain;
  }
  &.has-value {
    position: relative;
    border: 1px solid #000000;
    &:hover {
      .account-profile__avatar--overlay {
        opacity: 1;
      }
    }
  }
}

.account-company__avatar {
  width: 76px;
  height: 76px;
  border-radius: 5px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  overflow: hidden;
  position: relative;

  cursor: pointer;
  &.has-value {
    position: relative;
    &:hover {
      .account-profile__avatar--overlay {
        opacity: 1;
      }
    }
  }
  img {
    object-fit: contain;
  }
}

.account-label__wrapper {
  display: flex;
}

.account-label {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  padding-left: 4px;
  font-family: 'Object Sans Medium';
  padding-left: 0;
  margin-bottom: 18px;
  // margin-bottom: 5px;
  p {
    margin: 0;
    padding-left: 4px;
    padding-bottom: 3px;
  }
  input {
    border: 1px solid #d4d4d4;
    padding: 15.25px 13px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    &:first-child {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      flex-grow: 1;
    }
  }
}

.account-profile__avatar--overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  opacity: 0;
  transition: 0.3s ease opacity;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.optional {
  font-size: 12px;
  color: #c4c4c4;
  font-family: 'Object Sans';
  text-transform: initial;
}

.generic-input {
  &:focus {
    border: 1px solid #000000 !important;
  }
}

.generic-input--textarea {
  width: 100%;
  height: 120px;
  font-family: 'Object Sans';
  resize: none;
  padding: 14px 13px;
  border-radius: 3px;
  border: 1px solid #d5d4d4;
  font-size: 16px;
  outline: none;
  &:focus {
    border: 1px solid #000000;
  }
}
