.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m0 {
  margin: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.text-gray {
  color: #7d8693;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Object Sans Medium';
}

.information-icon {
  min-width: 13px;
  background-color: #8997ba;
  height: 13px;
  display: inline-flex;
  display: none;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  font-size: 11px;
  margin-left: 5px;
  cursor: default;
}

.center-vertically {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.text-danger {
  color: #c02222;
}

.text-hollow {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.button--red {
  font-size: 14px;
  color: rgba(225, 123, 123, 1);
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
}

.text-red {
  color: rgba(225, 123, 123, 1);
  cursor: pointer;
}

.text-medium {
  font-family: 'Object Sans Medium';
  font-style: 14px;
  &--small {
    font-size: 12px;
    margin-bottom: 4px;
    padding-left: 4px;
  }
  &--tiny {
    font-size: 10px;
    margin-bottom: 4px;
    padding-left: 4px;
  }
}

.text-bold {
  font-family: 'Object Sans Bold';
  font-style: 14px;
  &--small {
    font-size: 12px;
    margin-bottom: 4px;
    padding-left: 4px;
  }
  &--tiny {
    font-size: 10px;
    margin-bottom: 4px;
    padding-left: 4px;
  }
}

.image__full-width {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.spinner {
  animation: rotate 1.5s linear infinite;
}

.spinner--smaller {
  width: 20px;
  height: 20px;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.char-to-image {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-size: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
}

////////

.table__footer {
  font-size: 12px;
  position: fixed;
  bottom: 0px;
  padding: 0px 14px;
  width: calc(100% - 245px);
  height: 30px;
  z-index: 300;
  color: black;
  align-items: center;
  box-shadow: 0px -2px 4px #ededed;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.divider {
  height: 1px;
  margin: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  background-color: #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px -2px 4px #ededed;
  display: flex;
  align-items: center;
}

.button-filter__wrapper {
  position: relative;
  &:hover {
    .button-filter__tooltip {
      opacity: 1;
    }
  }
  &.active {
    .button-filter {
      background-color: #ddc7f9;
      border-color: #ba9be2;
    }
  }
}

.button-filter__dropdown {
  position: absolute;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  white-space: nowrap;
  min-width: 138px;
  padding: 10px 3px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;

  p {
    font-size: 13px;
    line-height: 13px;
    margin: 0;
    &.danger {
      color: #d66060;
    }
  }
}

.button-filter {
  font-size: 12px;
  color: color;
  padding: 7px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: background-color 0.3s;
  cursor: pointer;
  p {
    margin: 0px 0px 0px 5px;
    line-height: 12px;
  }
  &:hover {
    background-color: #f2f2f2;
  }
  &.active {
    background-color: #ddc7f9;
    border-color: #ba9be2;
  }
}

.button-filter__tooltip {
  position: absolute;
  font-size: 12px;
  left: 50%;
  transform: translatex(-50%);
  background: #7d8693;
  padding: 3px;
  border-radius: 3px;
  transition: all 0.3s;
  color: #ffffff;
  line-height: 12px;
  margin-top: 1px;
  opacity: 0;
  pointer-events: none;
}

.button-filter--danger {
  background-color: #d66060;
  &:hover {
    background-color: #de7b7b;
  }
  &.active {
    background-color: #de7b7b;
    border-color: #c55f5f;
  }
}

.classic-dropdown {
  cursor: pointer;
  padding: 5px 13px;
  &:hover {
    background-color: #f9f9f9;
  }
}
.classic-dropdown__has-icon {
  display: flex;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
  p {
    margin-left: 10px;
  }
}

.top-bar-search__wrapper {
  display: flex;
  gap: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: auto;
  padding: 8px 10px;
  input {
    background-color: transparent;
    flex-grow: 1;
    opacity: 0;
    pointer-events: none;
    border: none;
    font-size: 14px;
    outline: none;
    &::placeholder {
      color: #bec0c2;
    }
  }
  &.active {
    width: 273px;
    background-color: rgb(242, 242, 242);
    input {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.table-header-row {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    border-right: 1.5px solid #f2f2f2;
  }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   right: -1px;
  //   height: 100%;
  //   border-right: 1.5px solid #f2f2f2;
  // }
}

.contextContainer {
  z-index: 1;
  width: 100%;
  height: 500px;
  background: #673ab7;
}
.rightClick {
  z-index: 12;
  position: fixed;
  background: rgb(240, 200, 1);
}
.menuElement {
  color: #222222;
  cursor: pointer;
  padding: 17px 36px;
}
.menuElement:hover {
  color: #fff;
  background: #009688;
}

////
///
///
