.fu_title {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.dropzone {
  margin-top: 40px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d5d4d4;
  border-radius: 3px;
  width: 392px;
  height: 137px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fu_text {
  font-family: 'Object Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 16px;
}

.fu_subtext {
  font-family: 'Object Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

.fu_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
