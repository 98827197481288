.file_drop_zone {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  justify-content: center;
  max-height: 400px;
  overflow: auto;
}

.file_card {
  height: 60px;
  display: flex;
  align-items: center;
}

.file_card {
  cursor: move;
  height: 60px;
  width: 60px;
  min-width: 60px;
  background-color: #f9f9f9;
  margin-right: 10px;
}

.file_card_nav {
  display: flex;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  justify-content: space-between;
}

.file_card_actions {
  display: flex;
  gap: 2px;
}

.file_card_action_button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 3px;
  background: #ffffff;
  outline: none;
  border: none;
}

.file_card_action_button:hover {
  background-color: #f2f2f2;
}

.upload_more {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 2px;
  cursor: pointer;
}

.upload_more:hover {
  background-color: #f2f2f2;
}

.upload_file_title {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}
