.add_condition_btn {
  background: none;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 9px;
}

.add_condition_btn:hover {
  cursor: pointer;
  font-family: 'Object Sans Medium';
}

.apply_filter_btn {
  background: #000;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  padding: 5px 10px;
}

.apply_filter_btn:hover {
  cursor: pointer;
  font-family: 'Object Sans Medium';
}

.base_select_filters {
  border: 1px solid #f9f9f9;
  min-width: max-content;
  border-radius: 3px;
  padding: 3px 25px 3px 3px;
  font-size: 12px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../imgs/Chevron.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  background-size: 10px;
}

.close-handle {
  padding-left: 9px;
  text-align: center;
  cursor: pointer;
}

.data_title {
  font-size: 14px;
  font-family: 'Object Sans Medium';
  line-height: 8px;
  margin-left: 13px;
  margin-top: 13px;
}

.data_divider {
  height: 1px;
  margin: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  background-color: #d9d9d9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
