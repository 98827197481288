.select-label {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 23px;
  .label__optional {
    color: #c4c4c4;
    text-transform: initial;
  }
}

.select-label__wrapper {
  margin-bottom: 2px;
  padding-left: 4px;
  font-size: 12px;
  font-family: 'Object Sans Medium';
}

.react-select__indicator-separator {
  display: none;
}

.react-select-container {
  outline: none;
  .react-select__control {
    cursor: pointer;
    box-shadow: none;
    padding: 6.5px 5px;
    border-color: #d5d4d4 !important;

    &:hover {
      border-color: rgba(0, 0, 0, 0.25);
      box-shadow: none;
    }
  }

  .react-select__control--is-focused {
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid black !important;
  }

  .react-select__option--is-focused {
    background-color: #efefef;
    color: #000000;
  }

  .react-select__option--is-selected {
    background-color: #e7e7e7;
    color: #000000;
  }

  .react-select__placeholder {
    color: #d5d4d4 !important;
  }

  .react-select__menu {
    border-radius: 3px;
    border: 1px solid black;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 34px;
  }

  .react-select__menu-list {
    border-top: none;
  }
}
